<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "SysApiKeysIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("apikeys", {
      contents: state => state.api_keys
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProviders: "fetchAccountsForServiceProviders"
    }),
    ...mapActions("users", {
      getUsers: "getUsers"
    }),
    ...mapActions("apikeys", {
      setSystemAccess: "setSystemAccess"
    }),

    initContent() {
      return {
        is_enabled: true,
        api_key: "",
        api_key_name: "",
        user_id: "",
        ac_id: "",
        user_permissions: []
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.setSystemAccess(true);
    });
  },
  mounted() {
    this.contentType = "Sys API Key";
    this.primaryKey = "user_api_key_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
